import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../context/AuthContext';
import {
  showCompliancePage,
  showHelpPage,
  showJoinNowPage,
  showMyAccountPage,
  showPrivacyPolicyPage,
  showTermsOfUsePage
} from '../../../services/navigation/navigation.service';
import { getMiddleSectionHeight, isWeb, openNewBrowserTab } from '../../../services/util-service/util.service';
import { links } from '../../../links';
import ModalContainer from '../../ModalContainer/ModalContainer';
import SignIn from '../../SignIn/SignIn';
import NavigationLinks from '../../NavigationLinks/NavigationLinks';
import { useWLconfigContext } from '../../../context/WLConfigContext';

import './SideMenu.scss';

const SideMenu = (props) => {
  const {
    wl_config: { header_links = [], browse_config = [] } = {},
    variables: {
      REACT_APP_DISPLAY_AFFILIATE_PROGRAM,
      REACT_APP_WEBMASTERS_URL,
      REACT_APP_SUPPORT_EMAIL
    } = {}
  } = useWLconfigContext();

  const { t } = useTranslation();
  const { userLoggedIn, logout } = useAuthContext();

  const getSection3Items = () => {
    return [{
      key: 'instagram',
      label: 'instagram',
      onClick: openNewBrowserTab.bind(this, links.instagram),
    }, {
      key: 'twitter',
      label: t('SideMenu.twitter'),
      onClick: openNewBrowserTab.bind(this, links.twitter),
    }];
  };

  const getSection4Items = () => {
    const items =
      REACT_APP_DISPLAY_AFFILIATE_PROGRAM === 'on'
        ? [
          {
            key: 'webmasters',
            label: t('SideMenu.webmasters'),
            onClick: openNewBrowserTab.bind(this, REACT_APP_WEBMASTERS_URL),
          },
        ]
        : [];

    return items.concat({
      key: 'billingSupport',
      label: t('SideMenu.billingSupport'),
      onClick: showHelpPage,
      mailto: `mailto: ${REACT_APP_SUPPORT_EMAIL}`,
    }, {
      key: 'help',
      label: t('SideMenu.help'),
      onClick: showHelpPage,
    });
  };

  const getSection5Items = () => {
    return [{
      key: '2257Compliance',
      label: t('SideMenu.2257Compliance'),
      onClick: showCompliancePage,
    }, {
      key: 'termsOfUse',
      label: t('SideMenu.termsOfUse'),
      onClick: showTermsOfUsePage,
    }, {
      key: 'privacyPolicy',
      label: t('SideMenu.privacyPolicy'),
      onClick: showPrivacyPolicyPage,
    }];
  };

  const getSection6Items = () => {
    return userLoggedIn
      ? [{
        key: 'myAccount',
        label: t('SideMenu.myAccount'),
        onClick: showMyAccountPage,
      }, {
        key: 'signOut',
        label: t('SideMenu.signOut'),
        onClick: () => logout(),
      }]
      : [{
        key: 'joinNow',
        label: t('SideMenu.joinNow'),
        onClick: showJoinNowPage,
      }];
  };

  const renderSignIn = () => {
    if (userLoggedIn) return null;

    return (
      <div className="Section">
        <ModalContainer className='SignInModal' buttonText='Login'>
          <SignIn />
        </ModalContainer>
      </div>
    )
  };

  const renderEntry = ({ key, label, onClick, mailto }) => {
    const propsData = {
      className: 'Entry',
      key,
    };
    let view;
    if (mailto) {
      Object.assign(propsData, {
        href: mailto,
        onClick: onClick,
      });
      view = <a {...propsData}>{label}</a>;
    } else {
      propsData.onClick = onClick;
      view = <div {...propsData}>{label}</div>;
    }
    return view;
  };

  const renderSection = (items) => {
    const classes = ['Section'];

    return (
      <div className={classes.join(' ')}>
        {items.map(renderEntry)}
      </div>
    );
  };

  const classes = ['SideMenu'];
  const { className } = props;
  if (className) {
    classes.push(className);
  }
  const style = {
    height: getMiddleSectionHeight(true),
  };

  const header = document.querySelector('.MainDashboardHeader');
  if (header) {
    style.top = header.offsetTop + header.offsetHeight;
  }

  if (isWeb()) return null;

  return (
    <div className={classes.join(' ')} style={style}>
      <div className="Section">
        <NavigationLinks links={browse_config} className='Entry' />
      </div>
      {browse_config?.length !== 0 && <div className="Separator" />}
      <div className="Section">
        <NavigationLinks links={header_links} className='Entry' />
      </div>
      {header_links?.length !== 0 && <div className="Separator" />}

      {renderSection(getSection3Items())}
      <div className="Separator" />
      {renderSection(getSection4Items())}
      <div className="Separator" />
      {renderSection(getSection5Items())}
      <div className="Separator" />
      {renderSection(getSection6Items(), true)}
      {renderSignIn()}
    </div>
  );
};

SideMenu.propTypes = {
  className: PropTypes.string
};

export default SideMenu;
