import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ApplicationLogo from '../../../components/ApplicationLogo/ApplicationLogo';
import BrowseNavigation from '../../../components/BrowseNavigation/BrowseNavigation';
import MainSearch from '../../../components/MainSearch/MainSearch';
import UserAction from '../../../components/UserAction/UserAction';
import { ReactComponent as AgeVerifiedIcon } from '../../../images/ageVerifiedIcon.svg';
import ModalContainer from '../../../components/ModalContainer/ModalContainer';
import SignIn from '../../../components/SignIn/SignIn';
import NavigationLinks from '../../../components/NavigationLinks/NavigationLinks';

import { showMyAccountPage } from '../../../services/navigation/navigation.service';
import { getCookie, isWeb } from '../../../services/util-service/util.service';
import { useWLconfigContext } from '../../../context/WLConfigContext';
import { useAuthContext } from '../../../context/AuthContext';

import './MainDashboardHeader.scss';

const MainDashboardHeader = (props) => {
  const { handleSearchDropdownValue, toggleMobileMenu } = props;
  const [desktopSearchVisible, setDesktopSearchVisible] = useState(false);
  const ageVerContentRef = useRef(null);
  const ageVerVisible = useRef(false);
  const { t } = useTranslation();
  const { userLoggedIn, logout } = useAuthContext();
  const { wl_config: { header_links, browse_config = [] } = {} } = useWLconfigContext();

  useEffect(() => {
    const ageVerifiedClickOutside = (event) => {
      if (isWeb()) return;
      if (ageVerContentRef.current) {
        const elem = event.target;

        if (elem.classList.contains('AgeVerifiedContainer')) {
          ageVerVisible.current = !ageVerVisible.current;
          ageVerContentRef.current.style.display = ageVerVisible.current ? 'block' : 'none';
        } else {
          ageVerContentRef.current.style.display = 'none';
          ageVerVisible.current = false;
        }
      }
    };
    document.addEventListener('click', ageVerifiedClickOutside);

    return () => {
      document.removeEventListener('click', ageVerifiedClickOutside);
    };
  }, []);

  const renderNav = () => {
    return (
      <nav className="NavBar d-flex align-center">
        {!desktopSearchVisible ? (
          <>
            {browse_config?.length !== 0 && (
              <BrowseNavigation
                className='NavBar-Btn'
                baseText={t('BrowseNavigation.browse')}
              >
                <NavigationLinks links={browse_config} className='Dropdown-Item' />
              </BrowseNavigation>
            )}
            <NavigationLinks links={header_links} className='NavBar-Btn' />
          </>
        ) : (
          <i className="HeaderNavCollapseIndicator fas fa-bars" />
        )}

        {renderSearch()}
      </nav>
    );
  };

  const renderSearch = () => {
    return (
      <MainSearch
        toggleSearchVisible={(val) => setDesktopSearchVisible(val)}
        desktopSearchVisible={desktopSearchVisible}
        handleSearchDropdownValue={handleSearchDropdownValue}
      />
    );
  };

  const renderUserActions = () => {
    let view = (
      <>
        {/* <UserAction text={t('MainDashboardHeader.joinNow')} onClick={() => { navigate('/join') }} /> 
        <div className="Separator">|</div> */}
        <ModalContainer
          className='SignInModal'
          buttonText='LOGIN'
          showArrow
        >
          <SignIn />
        </ModalContainer>
      </>
    );

    if (userLoggedIn) {
      view = (
        <>
          <UserAction text={t('MainDashboardHeader.signOut')} onClick={() => logout()} />
          <div className="Separator">|</div>
          <UserAction text={t('MainDashboardHeader.myAccount')} onClick={() => showMyAccountPage()} />
        </>
      )
    }
    return <div className="UserActions">{view}</div>;
  };

  const renderMobileSearch = () => {
    if (!desktopSearchVisible || isWeb()) return null;
    return <div className="MobileSearchWrapper">{renderSearch()}</div>;
  };

  const renderAgeVerIcon = () => {
    let bavCookie = getCookie('bav');
    if (!bavCookie) return null;

    try {
      bavCookie = decodeURIComponent(bavCookie);
      bavCookie = window.atob(bavCookie);
    } catch (err) {
      console.error('Decoding bav failed:', err);
    }
    const bavToLocalFormat = new Date(bavCookie * 1000).toLocaleString(); // timestamp
    const validBavDate = bavToLocalFormat !== 'Invalid Date' ? bavToLocalFormat : '';

    return (
      <span className="AgeVerifiedContainer">
        <AgeVerifiedIcon />
        <div className="AgeVerifiedContent" ref={ageVerContentRef}>
          <div>Age verification successful.</div>
          <div>Verification valid until:</div>
          <div>{validBavDate}</div>
        </div>
      </span>
    );
  };

  const renderMobileIcons = () => {
    return (
      <>
        <i
          className="MobileSearchBtn fa fa-search"
          onClick={() => setDesktopSearchVisible((prevState) => !prevState)}
        />

        <i
          className="MobileMenuBtn fa fa-bars cursor-pointer"
          onClick={() => {
            if (toggleMobileMenu()) {
              toggleMobileMenu();
            }
            setDesktopSearchVisible(false);
          }}
        />
      </>
    );
  };

  return (
    <header className="MainDashboardHeader d-flex align-center">
      <div className="d-flex align-center">
        <ApplicationLogo />
        {renderNav()} {/* hidden in mobile view*/}
      </div>

      <div className="d-flex align-center">
        {renderUserActions()} {/* hidden in mobile view*/}
        {renderMobileIcons()}
        {renderAgeVerIcon()}
      </div>

      {renderMobileSearch()}
    </header>
  );
};

MainDashboardHeader.displayName = 'MainDashboardHeader';

export default MainDashboardHeader;
